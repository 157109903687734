import React, { useRef, useEffect } from 'react';
import { useLocation, Switch } from 'react-router-dom';
import AppRoute from './utils/AppRoute';

import ScrollReveal from './utils/ScrollReveal';

// Layouts
import Community from "../src/pages/community";

import Error from "./pages/Error";
import useToken, { uInfo } from './useToken';
import $ from 'jquery'
import { ApiUrl , MixpanelToken } from './config';
import ReactGA from 'react-ga';
import axios from 'axios';
import mixpanel from 'mixpanel-browser';
mixpanel.init(MixpanelToken, { debug: false });

const CommunityApp = (props) => {
  const childRef = useRef();
  let location = useLocation();

  useEffect(() => {
    document.body.classList.add('is-loaded');

    const paramsMatch = window.location.href.match(/\?.+/);
    if (paramsMatch) {
      const params = new URLSearchParams(paramsMatch[0]);
      const authToken = params.get('s');
      if (authToken != 'null') {
        localStorage.setItem('token', authToken)
        window.history.pushState({}, "", window.location.origin);
        // localStorage.token = authToken;
      }
    }

    if (uInfo()) {
      axios.get(ApiUrl + 'getGoogleAnalyticsKey', {}, {
        headers: {}
      })
        .then(function (res) {
          let key = 'UA-192424311-1';
          if (res.data.success === true) {
            key = res.data.lists.google_analytics;
          }
          ReactGA.initialize(key);
          ReactGA.set({ userId: uInfo().analytic_id });
          ReactGA.pageview(window.location.href);
          mixpanel.identify(uInfo().analytic_id);
          mixpanel.track(window.location.href, { 'source': window.location.href });

        })
        .catch(function (error) {
          console.log(error);
        });
    }

    // window.scrollTo(0, 0);
    $('html, body').animate({ scrollTop: 0 }, 1000);
    // childRef.current.init();
    // if (location.pathname != '/message') {
    //   let pusher = new Pusher('3086524ac1732fefac3c', {
    //     authEndpoint: `${ApiUrl}pusher/auth`,
    //     cluster: "mt1"
    //   })
    //   pusher.unsubscribe('my-channel');
    // }
  }, [location]);

  return (

    <>
      <ScrollReveal
        ref={childRef}
        children={() => (
          <Switch>
            <AppRoute path="/" component={Community} />
        
            <AppRoute component={Error} />
          </Switch>
        )} />
    </>
  );
}

export default CommunityApp;