import React, { useRef, useEffect, Suspense, lazy } from 'react';
import { useLocation, Switch } from 'react-router-dom';
import AppRoute from './utils/AppRoute';
import ProtectedRoute from './utils/ProtectedRoute';

import ScrollReveal from './utils/ScrollReveal';

// Layouts
import LayoutDefault from './layouts/LayoutDefault';

import Directories from "../src/pages/webapp/index";
import MListing from "../src/pages/webapp/listing";
import MListingDetails from "../src/pages/webapp/MListingDetails";
import MListingGallery from "./pages/webapp/ListingGallery";
import MDirections from "./pages/webapp/MDirections";

import BuyerPublicProfileOverview from "./pages/user/public/BuyerProfileOverview";
import BuyerPublicReviews from "./pages/user/public/BuyerReviews"
import BuyerPublicFriends from "./pages/user/public/BuyerFriends"
import BuyerPublicBookmark from "./pages/user/public/BuyerBookmark"
import BuyerPublicCheckIn from "./pages/user/public/BuyerCheckIn"
import BuyerPublicPhoto from "./pages/user/public/BuyerPhoto";
import Error from "./pages/Error";
import useToken, { uInfo } from './useToken';
import $ from 'jquery'
import { ApiUrl, MixpanelToken } from './config';
import ReactGA from 'react-ga';
import axios from 'axios';
import AllKatikaCategories from './pages/directories/AllCategories';
import mixpanel from 'mixpanel-browser';
mixpanel.init(MixpanelToken, { debug: false });

const App = (props) => {
  const childRef = useRef();
  let location = useLocation();
  const { setToken } = useToken();

  // if(!token) {
  //   return <Login setToken={setToken} />
  // }


  useEffect(() => {
    document.body.classList.add('is-loaded');

    const paramsMatch = window.location.href.match(/\?.+/);
    if (paramsMatch) {
      const params = new URLSearchParams(paramsMatch[0]);
      const authToken = params.get('s');
      if (authToken != 'null') {
        localStorage.setItem('token', authToken)
        window.history.pushState({}, "", window.location.origin);
        // localStorage.token = authToken;
      }
    }

    if (uInfo()) {
      axios.get(ApiUrl + 'getGoogleAnalyticsKey', {}, {
        headers: {}
      })
        .then(function (res) {
          let key = 'UA-192424311-1';
          if (res.data.success === true) {
            key = res.data.lists.google_analytics;
          }
          ReactGA.initialize(key);
          ReactGA.set({ userId: uInfo().analytic_id });
          ReactGA.pageview(window.location.href);
          mixpanel.identify(uInfo().analytic_id);
          mixpanel.track(window.location.href, { 'source': window.location.href });

        })
        .catch(function (error) {
          console.log(error);
        });
    }

    // window.scrollTo(0, 0);
    $('html, body').animate({ scrollTop: 0 }, 1000);
    // childRef.current.init();
    // if (location.pathname != '/message') {
    //   let pusher = new Pusher('3086524ac1732fefac3c', {
    //     authEndpoint: `${ApiUrl}pusher/auth`,
    //     cluster: "mt1"
    //   })
    //   pusher.unsubscribe('my-channel');
    // }
  }, [location]);

  return (
    <ScrollReveal
      ref={childRef}
      children={() => (
        <Suspense fallback={<div>Loading...</div>}>
          <Switch>
            <AppRoute path="/listing/:id?" component={MListing} />
            <AppRoute path="/biz/:id" component={MListingDetails} />
            <AppRoute path="/listing-gallery/:id" component={MListingGallery} />
            <AppRoute path="/maps/:id" component={MDirections} />


            {/* //////////// Login Account */}

            {/* Business Profile */}

            {/* <ProtectedRoute path="/dashboard" component={UDashboard} requiredRoles={[0, 2]} />
            <ProtectedRoute path="/account" component={Account} requiredRoles={[0, 2]} />
            <ProtectedRoute path="/detailed-info" component={DetailedInfo} requiredRoles={[0, 2]} />
            <ProtectedRoute path="/security" component={Security} requiredRoles={[0, 2]} />
            <ProtectedRoute path="/schedule" component={Schedule} requiredRoles={[0, 2]} />
            <ProtectedRoute path="/reviews" component={Reviews} requiredRoles={[0, 2]} />
            <ProtectedRoute path="/message" component={Message} requiredRoles={[0, 2]} />
            <ProtectedRoute path="/stats" component={Stats} requiredRoles={[0, 2]} />
            <ProtectedRoute path="/billing-history" component={BillingHistory} requiredRoles={[0, 2]} /> */}


            {/* ///////////////////public */}

            <AppRoute path="/user-profile/:id" component={BuyerPublicProfileOverview} />
            <AppRoute path="/user-reviews/:id" component={BuyerPublicReviews} />
            <AppRoute path="/user-friends/:id" component={BuyerPublicFriends} />
            <AppRoute path="/user-bookmarks/:id" component={BuyerPublicBookmark} />
            <AppRoute path="/user-check-in/:id" component={BuyerPublicCheckIn} />
            <AppRoute path="/user-photo/:id" component={BuyerPublicPhoto} />
            <AppRoute path="/all-categories" component={AllKatikaCategories} />



            {/* <ProtectedRoute path="/buyer-manage-friends" component={BuyerManageFriends} requiredRoles={[1]} />
            <ProtectedRoute path="/external-applications" component={ExternalApplications} requiredRoles={[1]} />
            <ProtectedRoute path="/buyer-photo" component={BuyerPhoto} requiredRoles={[1]} />

            <ProtectedRoute path="/find-friends" component={FindFriend} requiredRoles={[1]} />
            <ProtectedRoute path="/find-friends-fb" component={FindFriendFB} requiredRoles={[1]} /> */}

            {/* ///////////////////Footer Page //////////////// */}




            {/* ///////////////////Footer Page //////////////// */}

            {/* /////////////// login user ///////////// */}
            <AppRoute exact path="/" component={Directories} />
            {/* <AppRoute path="/directories" component={Directories} /> */}
            {/* <AppRoute path="/marketplace" component={Marketplace} /> */}
            {/* <AppRoute path="/index1" component={Home} /> */}

            {/* <AppRoute path="/login" setToken={setToken} component={Login} /> */}
            {/* <AppRoute path="/business-sign-up" component={BusinessSignUp} /> */}
            <AppRoute component={Error} />
          </Switch>
        </Suspense>
      )} />
  );
}

export default App;